import { createAction, props, on, On } from "@ngrx/store";
import { removeArrayControl } from "ngrx-forms";

import { currentFixtureStateReducer } from "../fixture/reducer";
import { FixturesState } from "../model/state";

/* ACTIONS */
const ACTION_NAME = "[Vessel Renomination] Remove Row";

export const removeVesselRenominationAction = createAction(ACTION_NAME, props<{ rowIndex: number }>());

/* REDUCERS */
export const removeVesselRenominationReducer: On<FixturesState> = on(removeVesselRenominationAction, (state, { rowIndex }) =>
    currentFixtureStateReducer(state, (fixtureState) => ({
        ...fixtureState,
        vesselNominationForms: removeArrayControl(rowIndex)(fixtureState.vesselNominationForms)
    }))
);
