import { createSelector } from "@ngrx/store";

import { selectCurrentUser } from "@ops/state";

import { Division, FixtureType } from "../../shared/models";
import { selectFixturesFeature } from "../fixture.selectors";
import { FixtureFeatureState, FixtureId, FixturesState, FixtureState, getCurrentFixtureId } from "../model";

export const selectCurrentFixtureId = createSelector(selectFixturesFeature, getCurrentFixtureId);

export const selectFixtureState = createSelector<FixtureFeatureState, { fixtureId: FixtureId }, FixturesState, FixtureState>(
    selectFixturesFeature,
    (state: FixturesState, { fixtureId }) => (fixtureId ? state.fixtures.byId[fixtureId] : null)
);

export const selectCurrentFixtureState = createSelector<FixtureFeatureState, FixturesState, FixtureId, FixtureState>(
    selectFixturesFeature,
    selectCurrentFixtureId,
    (state, fixtureId) => (fixtureId ? state.fixtures.byId[fixtureId] : null)
);

export const selectCurrentFixture = createSelector(selectCurrentFixtureState, (state) => (state ? state.workingFixture || state.fixture : null));
export const selectCurrentFixtureType = createSelector(selectCurrentFixture, (fixture) => (fixture ? <FixtureType>fixture.fixtureType.id : null));
export const selectCurrentFixtureDivision = createSelector(selectCurrentFixture, (fixture) => (fixture ? <Division>fixture.division.id : null));
export const selectCurrentFixtureLockedByCurrentUser = createSelector(
    selectCurrentFixture,
    selectCurrentUser,
    (fixture, user) => <boolean>(!!fixture && !!user && !!fixture.lockedBy && fixture.lockedBy.userId === user.userId)
);

export const selectCurrentFixturePersisting = createSelector(selectCurrentFixtureState, (state) => state?.persistenceStatus === "persisting");
export const selectCurrentFixtureLoading = createSelector(selectCurrentFixtureState, (state) => state?.loadStatus === "loading");
export const selectCurrentFixtureBusy = createSelector(selectCurrentFixturePersisting, selectCurrentFixtureLoading, (persisting, loading) => persisting || loading);

export const selectCurrentFixtureVoyagesLoading = createSelector(selectCurrentFixtureState, (state) => !state || state.voyagesLoadStatus === "loading");
export const selectCurrentFixtureVoyageIds = createSelector(selectCurrentFixtureState, (state) => (state ? state.voyageIds : null) || []);

export const selectFixtureVoyageIds = createSelector(selectFixtureState, (state) => (state ? state.voyageIds : []));

export const selectCurrentFixtureHasTCVoyages = createSelector(
    selectCurrentFixtureType,
    selectCurrentFixtureVoyageIds,
    (fixtureType, voyagesIds) => fixtureType === FixtureType.TimeCharter && voyagesIds.length > 0
);

export const selectIsVoyageFixture = createSelector(selectCurrentFixtureType, (fixtureType) => fixtureType === FixtureType.Voyage);

export const selectCurrentFixtureFreightType = createSelector(selectCurrentFixture, (fixture) => (fixture ? fixture.freightType : null));

export const selectCurrentFixtureDismissedWarnings = createSelector(selectCurrentFixtureState, (state) => (state ? state.dismissedWarnings : null));

export const selectCurrentDeliveryBunkers = createSelector(selectCurrentFixture, (state) => state?.delivery.bunkers);
export const selectCurrentCpBunkersConsumption = createSelector(selectCurrentFixture, (state) => state?.cpSpeedAndConsumption.cpBunkersConsumption);

export const selectCurrentFixtureVesselNominationForms = createSelector(selectCurrentFixtureState, (state) => state?.vesselNominationForms);
