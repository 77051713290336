import { ChangeDetectionStrategy, Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { FormArrayState } from "ngrx-forms";
import { Observable } from "rxjs";

import { VesselNominationForm } from "./vessel-nomination-form";
import { FixtureFeatureState, selectCurrentFixtureVesselNominationForms } from "../state";
import { addVesselRenominationAction } from "../state/vessel-renomination/add-vessel-renomination";
import { initVesselRenominationsAction } from "../state/vessel-renomination/init-vessel-renominations";
import { removeVesselRenominationAction } from "../state/vessel-renomination/remove-vessel-renomination";

@Component({
    selector: "ops-vessel-renomination-popup",
    templateUrl: "./vessel-renomination-popup.component.html",
    styleUrls: ["./vessel-renomination-popup.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VesselRenominationPopupComponent implements OnInit {
    private appendCurrentVessel: boolean;

    vesselNominationForms$: Observable<FormArrayState<VesselNominationForm>>;

    constructor(@Inject(MAT_DIALOG_DATA) data: boolean, private dialogRef: MatDialogRef<VesselRenominationPopupComponent>, private store: Store<FixtureFeatureState>) {
        this.appendCurrentVessel = data;
        this.vesselNominationForms$ = store.select(selectCurrentFixtureVesselNominationForms);
    }

    ngOnInit(): void {
        this.store.dispatch(initVesselRenominationsAction({ appendCurrentVessel: this.appendCurrentVessel }));
    }

    addRow(rowIndex: number) {
        this.store.dispatch(addVesselRenominationAction({ rowIndex }));
    }

    removeRow(rowIndex: number) {
        this.store.dispatch(removeVesselRenominationAction({ rowIndex }));
    }

    cancel() {
        this.dialogRef.close("cancel");
    }

    apply() {
        this.dialogRef.close("ok");
    }
}
