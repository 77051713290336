import { createAction, on, On, props } from "@ngrx/store";
import { box, createFormArrayState } from "ngrx-forms";

import { Fixture } from "../../shared/models";
import { VesselNomination } from "../../shared/models/dtos/vessel-nomination.dto";
import { VesselNominationForm } from "../../vessel-renomination/vessel-nomination-form";
import { currentFixtureStateReducer } from "../fixture/reducer";
import { FixturesState } from "../model/state";

/* ACTIONS */
const ACTION_NAME = "[Vessel Renomination] Init";

export const initVesselRenominationsAction = createAction(ACTION_NAME, props<{ appendCurrentVessel: boolean }>());

/* REDUCERS */
export const initVesselRenominationsReducer: On<FixturesState> = on(initVesselRenominationsAction, (state, { appendCurrentVessel }) =>
    currentFixtureStateReducer(state, (fixtureState) => ({
        ...fixtureState,
        vesselNominationForms: createVesselNominationFormsState(fixtureState.workingFixture, appendCurrentVessel)
    }))
);

export const createVesselNominationFormsState = (fixture: Fixture, appendCurrentVessel: boolean) => {
    const forms: VesselNominationForm[] = [];
    const hasHistory = !!fixture.vesselNominationHistory?.length;
    if (hasHistory) {
        forms.push(...toVesselNominationForms(fixture.vesselNominationHistory));
    }
    if (appendCurrentVessel && fixture.vessel) {
        forms.push({
            nominationType: hasHistory ? "" : "Initial",
            nominationDate: hasHistory ? "" : fixture.vesselNominationDate ?? "",
            nominatedVessel: box(fixture.vessel),
            renominationReason: hasHistory ? "" : "Not Applicable"
        });
    }
    return createFormArrayState(`${fixture.fixtureId}.vesselNominationForms`, forms);
};

const toVesselNominationForms = (vesselNominationHistory: ReadonlyArray<VesselNomination>) =>
    vesselNominationHistory?.map((item) => ({
        nominationType: item.nominationType?.name,
        nominationDate: item.nominationDate,
        nominatedVessel: box(item.vessel),
        renominationReason: item.renominationReason?.name
    }));
