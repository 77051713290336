import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { Division } from "./division";
import { Enumeration } from "./enumeration";
import { ReferenceDataType } from "./reference-data-type";
import { AppConfigService } from "../../core/app-config.service";
import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { Currency } from "../../fixture/shared/models/dtos/currency.dto";
import { Division as DivisionEnum } from "../../fixture/shared/models/enums/division";
import { FixtureType } from "../../fixture/shared/models/enums/fixture-type";
import { memoizeObservable } from "../caching/memoize-observable";

export interface IReferenceDataService {
    getDurationTypes(): Observable<Enumeration[]>;
    getCpTypes(): Observable<Enumeration[]>;
    getBunkerTypes(): Observable<Enumeration[]>;
    getCurrencies(): Observable<Currency[]>;
    getFixtureStatuses(): Observable<Enumeration[]>;
    getDemurrageStatuses(): Observable<Enumeration[]>;
    getFreightRateUnits(): Observable<Enumeration[]>;
    getToleranceOptions(): Observable<Enumeration[]>;
    getQuantityUnits(): Observable<Enumeration[]>;
    getLaytimeEventTypes(): Observable<Enumeration[]>;
    getExpenseTypes(): Observable<Enumeration[]>;
    getAllocatedStatuses(): Observable<Enumeration[]>;
    getDemurrageClaimTypes(): Observable<Enumeration[]>;
    getDemurrageItemClaimTypes(): Observable<Enumeration[]>;
    getBerthActivityTypes(): Observable<Enumeration[]>;
    getCompanyRoles(): Observable<Enumeration[]>;
    getLoiStatuses(): Observable<Enumeration[]>;
    getNoticeTypes(): Observable<Enumeration[]>;
    getFixtureSectors(): Observable<Readonly<Division>[]>;
    getFixtureTypes(): Observable<Enumeration[]>;
    getManagedByTypes(): Observable<Enumeration[]>;
    getBeaufortScales(): Observable<Enumeration[]>;
    getRateDescriptions(): Observable<Enumeration[]>;
    getAssociatedCargoExpenseUnits(): Observable<Enumeration[]>;
    getDemurrageReasons(): Observable<Enumeration[]>;
    getLaycanExtensionReasons(): Observable<Enumeration[]>;
    getCancellationReasons(): Observable<Enumeration[]>;
    getPresentationTypes(): Observable<Enumeration[]>;
    getVesselNominationTypes(): Observable<Enumeration[]>;
    getVesselRenominationReasons(): Observable<Enumeration[]>;

    getReferenceDataByType(type: ReferenceDataType): Observable<Enumeration[]>;

    /**
     * @deprecated Use Enumeration.compare
     */
    compare(e1: Enumeration, e2: Enumeration): boolean;
    compareCurrencies(c1: Currency, c2: Currency): boolean;
}

// TODO: Move fixture specific parts to fixture module to prevent circular dependency
@Injectable({ providedIn: "root" })
export class ReferenceDataService implements IReferenceDataService {
    private readonly referenceDataUrl: string;
    private readonly get: (...a: any[]) => any;

    /**
     * @deprecated Use Enumeration.compare
     */
    compare = Enumeration.compare;

    constructor(private fixtureDataService: FixtureDataService, private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.referenceDataUrl = appConfigService.config.referenceDataUrl;

        this.get = memoizeObservable(this.internalGet);
    }

    getDurationTypes = () => this.get("durationType");
    getCpTypes = () => this.get("cpType", this.fixtureDataService.fixtureType, this.fixtureDataService.division);
    getBunkerTypes = () => this.get("bunkerType");
    getCurrencies = () => this.get("currency").pipe(map((enumerations: any[]) => enumerations.map((e: any) => ({ code: e.name }))));
    getFixtureStatuses = () => this.get("fixtureStatus", this.fixtureDataService.fixtureType, this.fixtureDataService.division);
    getDemurrageStatuses = () => this.get("demurrageStatus");
    getFreightRateUnits = () => this.get("freightRateUnit", this.fixtureDataService.fixtureType, this.fixtureDataService.division);
    getToleranceOptions = () => this.get("toleranceOption");
    getToleranceUnits = () => this.get("toleranceUnit");
    getQuantityUnits = () => this.get("quantityUnit", this.fixtureDataService.fixtureType, this.fixtureDataService.division);
    getLaytimeEventTypes = () => this.get("laytimeEvent");
    getExpenseTypes = () => this.get("expenseType");
    getAllocatedStatuses = () => this.get("allocatedStatus");
    getDemurrageClaimTypes = () => this.get("demurrageClaimType", this.fixtureDataService.fixtureType, this.fixtureDataService.division);
    getDemurrageItemClaimTypes = () => this.get("demurrageItemClaimType", this.fixtureDataService.fixtureType, this.fixtureDataService.division);
    getBerthActivityTypes = () => this.get("berthActivityType", this.fixtureDataService.fixtureType, this.fixtureDataService.division);
    getCompanyRoles = () => this.get("companyRole");
    getLoiStatuses = () => this.get("loiStatus", this.fixtureDataService.fixtureType, this.fixtureDataService.division);
    getNoticeTypes = () => this.get("noticeType", this.fixtureDataService.fixtureType, this.fixtureDataService.division);
    getFixtureSectors = () => this.get("division");
    getFixtureTypes = () => this.get("fixtureType");
    getManagedByTypes = () => this.get("managedByType");
    getHireRateUnits = () => this.get("hireRateUnit");
    getExerciseOptions = () => this.get("exerciseOptionType");
    getVoyageStatus = () => this.get("voyageStatus");
    getBeaufortScales = () => of(Array.from({ length: 13 }, (v, k) => new Enumeration(k, k.toString(), k)));
    getRateDescriptions = () => this.get("rateDescription");
    getAssociatedCargoExpenseUnits = () => this.get("associatedCargoExpenseUnit");
    getDemurrageReasons = () => this.get("demurrageReason");
    getPortCostStatus = () => this.get("portCostStatus");
    getCpRateUnits = () => this.get("cpRateUnit", this.fixtureDataService.fixtureType);
    getWorkingDayTypes = () => this.get("workingDayType", this.fixtureDataService.fixtureType);
    getWeekDays = () => this.get("weekDays", this.fixtureDataService.fixtureType);
    getReversibleLaytimeType = () => this.get("reversibleLaytimeType", this.fixtureDataService.fixtureType);
    getLaycanExtensionReasons = () => this.get("laycanExtensionReason");
    getFreightTypes = () => this.get("freightType");
    getCancellationReasons = () => this.get("CancellationReason");
    getPresentationTypes = () => this.get("presentationType");
    getVesselNominationTypes = () => this.get("vesselNominationType", this.fixtureDataService.fixtureType);
    getVesselRenominationReasons = () => this.get("vesselRenominationReason", this.fixtureDataService.fixtureType);

    getReferenceDataByType(type: ReferenceDataType, fixtureTypeId?: FixtureType, division?: DivisionEnum): Observable<Enumeration[]> {
        switch (type) {
            case "CpType":
            case "FixtureStatus":
            case "FreightRateUnit":
            case "QuantityUnit":
            case "DemurrageClaimType":
            case "DemurrageItemClaimType":
            case "BerthActivityType":
            case "LoiStatus":
            case "NoticeType":
                return this.get(type, fixtureTypeId || this.fixtureDataService.fixtureType, division || this.fixtureDataService.division);
            case "CpRateUnit":
            case "WorkingDayType":
            case "WeekDays":
            case "ReversibleLaytimeType":
                return this.get(type, fixtureTypeId || this.fixtureDataService.fixtureType);
            case "BeaufortScales":
                return this.getBeaufortScales();
            case "Currency":
                return this.getCurrencies();
            default:
                return this.get(type);
        }
    }

    compareCurrencies(c1: Currency, c2: Currency): boolean {
        return c1 && c2 ? c1.code === c2.code : c1 === c2;
    }

    private internalGet = (typeName: string, fixtureTypeId?: FixtureType, division?: DivisionEnum): Observable<Array<Enumeration>> => {
        if (division && fixtureTypeId) {
            const divisionName = DivisionEnum[division];
            const fixtureTypeName = FixtureType[fixtureTypeId];
            return this.httpClient.get<Array<Enumeration>>(`${this.referenceDataUrl}/api/v1.0/referenceData/${typeName}/${fixtureTypeName}/${divisionName}`);
        } else if (fixtureTypeId) {
            const fixtureTypeName = FixtureType[fixtureTypeId];
            return this.httpClient.get<Array<Enumeration>>(`${this.referenceDataUrl}/api/v1.0/referenceData/${typeName}/${fixtureTypeName}`);
        } else {
            return this.httpClient.get<Array<Enumeration>>(`${this.referenceDataUrl}/api/v1.0/referenceData/${typeName}`);
        }
    };
}
