import { createAction, props, on, On } from "@ngrx/store";
import { addArrayControl, box } from "ngrx-forms";

import { VesselNominationForm } from "../../vessel-renomination/vessel-nomination-form";
import { currentFixtureStateReducer } from "../fixture/reducer";
import { FixturesState } from "../model/state";

/* ACTIONS */
const ACTION_NAME = "[Vessel Renomination] Add";

export const addVesselRenominationAction = createAction(ACTION_NAME, props<{ rowIndex: number }>());

/* REDUCERS */
export const addVesselRenominationReducer: On<FixturesState> = on(addVesselRenominationAction, (state, { rowIndex }) =>
    currentFixtureStateReducer(state, (fixtureState) => ({
        ...fixtureState,
        vesselNominationForms: addArrayControl(fixtureState.vesselNominationForms, createEmptyRow(), rowIndex)
    }))
);

const createEmptyRow = (): VesselNominationForm => ({
    nominationType: "",
    nominationDate: "",
    nominatedVessel: box(null),
    renominationReason: ""
});
