<div class="vessel-renomination-history-popup-container">
    <div class="vessel-renomination-history-popup-header">
        <h5>Vessel Nomination History</h5>
        <button type="button" class="btn-close has-icon icon--close" (click)="cancel()"></button>
    </div>
    <div class="vessel-renomination-history-popup-body">
        <p>This table displays the vessel nomination history for the current fixture. Use the buttons to the right of
            the row to add a new nomination or remove the existing one and enter details for the Nominated Vessel,
            Nomination Date, and Renomination Reason.
        </p>
        <ops-vessel-renomination-table [vesselNominationForms]="vesselNominationForms$ | async"
            (addRow)="addRow($event)" (removeRow)="removeRow($event)"></ops-vessel-renomination-table>
    </div>
    <div class="vessel-renomination-history-popup-footer">
        <ops-action-button (click)="cancel()">CANCEL</ops-action-button>
        <ops-action-button type="primary" (click)="apply()">OK</ops-action-button>
    </div>
</div>