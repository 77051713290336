<div [formGroup]="headingForm" class="voyage-heading pb-0 pt-0">
    <div class="row no-gutters">
        <div class="col-7">
            <div class="row no-gutters">
                <div class="form-group col-6">
                    <label for="operators">Operator ({{ operatorCount }})</label>
                    <ops-user-autosuggest
                        [multiple]="true"
                        [lead]="leadOperator"
                        [fixtureId]="fixture.fixtureId"
                        [fixtureSource]="fixture.fixtureSource"
                        formControlName="operators"
                        data-test-operators
                    ></ops-user-autosuggest>
                </div>
                <div class="form-group col-3">
                    <label for="claims">Claims</label>
                    <ops-user-autosuggest
                        [multiple]="true"
                        formControlName="claims"
                        [fixtureId]="fixture.fixtureId"
                        [fixtureSource]="fixture.fixtureSource"
                        data-test-claims
                    ></ops-user-autosuggest>
                </div>
                <div class="form-group col-3">
                    <label for="displayBroker">Gateway Broker</label>
                    <ops-user-autosuggest
                        [multiple]="false"
                        [returnAll]="true"
                        [fixtureId]="fixture.fixtureId"
                        [fixtureSource]="fixture.fixtureSource"
                        formControlName="displayBroker"
                        data-test-display-broker
                    ></ops-user-autosuggest>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="form-group col-3 required">
                    <label for="laycanFrom">Laycan From</label>
                    <ops-date-input
                        formControlName="laycanFrom"
                        [enableTime]="true"
                        [opsValidationPopover]="laycanFromValidation"
                        timeZone="utc"
                        [defaultFocusDate]="fixture.fixtureSource.id === FixtureSource.Ops ? headingForm.controls['cpDate'].value : null"
                        data-test-laycan-from
                    ></ops-date-input>
                    <ng-template #laycanFromValidation>
                        <ops-simple-field-validation
                            fieldDisplayName="Laycan From"
                            [control]="headingForm.controls['laycanFrom']"
                        ></ops-simple-field-validation>
                    </ng-template>
                </div>
                <div class="form-group col-3 required">
                    <label for="laycanTo">Laycan To</label>
                    <ops-date-input
                        formControlName="laycanTo"
                        [enableTime]="true"
                        [opsValidationPopover]="laycanToValidation"
                        timeZone="utc"
                        [defaultFocusDate]="fixture.fixtureSource.id === FixtureSource.Ops ? headingForm.controls['cpDate'].value : null"
                        data-test-laycan-to
                    ></ops-date-input>
                    <ng-template #laycanToValidation>
                        <ops-simple-field-validation fieldDisplayName="Laycan To" [control]="headingForm.controls['laycanTo']"></ops-simple-field-validation>
                    </ng-template>
                </div>
                <div class="form-group col-3" [ngClass]="{ required: !!headingForm.value.laycanExtFrom || !!headingForm.value.laycanExtTo }">
                    <label for="laycanExtFrom">Laycan Ext. From</label>
                    <ops-date-input
                        timeZone="utc"
                        formControlName="laycanExtFrom"
                        [enableTime]="true"
                        [opsValidationPopover]="laycanExtFromValidation"
                        [defaultFocusDate]="headingForm.controls['laycanFrom'].value"
                        data-test-laycan-ext-from
                    ></ops-date-input>
                    <ng-template #laycanExtFromValidation>
                        <ops-simple-field-validation
                            fieldDisplayName="Laycan Ext. From"
                            [control]="headingForm.controls['laycanExtFrom']"
                        ></ops-simple-field-validation>
                    </ng-template>
                </div>
                <div class="form-group col-3" [ngClass]="{ required: !!headingForm.value.laycanExtFrom || !!headingForm.value.laycanExtTo }">
                    <label for="laycanExtTo">Laycan Ext. To</label>
                    <ops-date-input
                        timeZone="utc"
                        formControlName="laycanExtTo"
                        [enableTime]="true"
                        [opsValidationPopover]="laycanExtToValidation"
                        [defaultFocusDate]="headingForm.controls['laycanTo'].value"
                        data-test-laycan-ext-to
                    ></ops-date-input>
                    <ng-template #laycanExtToValidation>
                        <ops-simple-field-validation
                            fieldDisplayName="Laycan Ext. To"
                            [control]="headingForm.controls['laycanExtTo']"
                        ></ops-simple-field-validation>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="col-5">
            <div class="row no-gutters">
                <div class="form-group col-5 required">
                    <div class="vessel-name-label-container">
                        <label>Vessel Name</label>
                        <a *ngIf="isRenominationHistoryLinkEnabled" class="renomination-history-label"
                            (click)="openVesselRenominationDialog(false)">Renomination History</a>
                        </div>
                    <div class="d-flex ml-auto flex-row vessel-name-wrap">
                        <ops-vessel-autosuggest
                            [opsValidationPopover]="vesselValidation"
                            validationPopoverContainer="body"
                            [multiple]="false"
                            [selection]="fixture.vessel"
                            class="vessel-name"
                            formControlName="vessel"
                            (blur)="removeIsVesselTbn()"
                            data-test-vessel-search
                        ></ops-vessel-autosuggest>

                        <div class="d-flex">
                            <input
                                id="isVesselTbn"
                                type="checkbox"
                                class="tbn-checkbox"
                                name="isVesselTbn"
                                formControlName="isVesselTbn"
                                (change)="removeVessel()"
                                data-test-is-vessel-tbn
                            />
                            <label for="isVesselTbn" class="tbn-label">TBN</label>
                        </div>
                    </div>
                    <ng-template #vesselValidation>
                        <ops-simple-field-validation
                            [userMessages]="{ required: 'Required, or select TBN' }"
                            fieldDisplayName="Vessel Name"
                            [control]="headingForm.controls['vessel']"
                        ></ops-simple-field-validation>
                    </ng-template>
                </div>
                <div class="form-group col-3">
                    <div class="label-with-checkbox">
                        <label for="cancellationReason">Cancellation Reason</label>
                        <div class="checkbox" *opsDivisions="Division.pcg">
                            <label
                                for="includeCancelledInKpi"
                                title="Click to include a cancelled voyage against the Laycan Performance metric"
                            >KPI</label>
                            <input
                                id="includeCancelledInKpi"
                                type="checkbox"
                                name="includeCancelledInKpi"
                                formControlName="includeCancelledInKpi"
                                data-test-include-cancelled-in-kpi
                            />
                        </div>
                    </div>
                    <ng-select
                        id="cancellationReason"
                        class="ops-select form-control"
                        formControlName="cancellationReason"
                        [compareWith]="referenceDataService.compare"
                        [items]="cancellationReasons$ | async"
                        [clearable]="true"
                        bindLabel="name"
                        [selectOnTab]="true"
                        data-test-cancelation-reason
                    >
                    </ng-select>
                </div>
                <div class="form-group col-4 required">
                    <label for="fixtureStatus">Fixture Status</label>
                    <ng-select
                        id="fixtureStatus"
                        class="ops-select form-control"
                        formControlName="fixtureStatus"
                        warningFormControl
                        [compareWith]="referenceDataService.compare"
                        [items]="fixtureStatuses$ | async"
                        [clearable]="false"
                        bindLabel="name"
                        [selectOnTab]="true"
                        data-test-fixture-status
                    >
                    </ng-select>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="form-group col-4">
                    <label for="laycanExtensionReason">Laycan Ext. Reason</label>
                    <ng-select
                        id="laycanExtensionReason"
                        class="ops-select form-control"
                        formControlName="laycanExtensionReason"
                        [compareWith]="referenceDataService.compare"
                        [items]="laycanExtensionReasons$ | async"
                        [clearable]="true"
                        bindLabel="name"
                        [selectOnTab]="true"
                        data-test-laycan-extension-reason
                    >
                    </ng-select>
                </div>
                <div class="form-group col-4">
                    <label for="loiStatus">LOI Status</label>
                    <ng-select
                        id="loiStatus"
                        class="ops-select form-control"
                        formControlName="loiStatus"
                        [compareWith]="referenceDataService.compare"
                        [items]="loiStatuses$ | async"
                        [clearable]="false"
                        bindLabel="name"
                        [selectOnTab]="true"
                        data-test-loi-status
                    >
                    </ng-select>
                </div>
                <div class="form-group col-4" *opsDivisions="[Division.dryCargo, Division.gas, Division.pcg]">
                    <label for="lastCargoes">Last Cargoes ({{ lastCargoCount }})</label>
                    <ops-cargo-autosuggest [multiple]="true" formControlName="lastCargoes" data-test-last-cargoes></ops-cargo-autosuggest>
                </div>
            </div>
        </div>
    </div>

    <div class="row no-gutters">
        <div class="col-7">
            <div class="row no-gutters">
                <div class="form-group col-3 pl-0 pr-0">
                    <div class="row no-gutters">
                        <div class="form-group col-6 pt-0 pb-0">
                            <label for="currency">Currency</label>
                            <ng-select
                                id="currency"
                                class="ops-select form-control"
                                formControlName="currency"
                                [compareWith]="referenceDataService.compareCurrencies"
                                [items]="currencies$ | async"
                                [clearable]="false"
                                bindLabel="code"
                                [selectOnTab]="true"
                                data-test-currency
                            >
                            </ng-select>
                        </div>
                        <div class="form-group col-6 pt-0 pb-0 required">
                            <label for="cpDate">CP Date</label>
                            <ops-date-input
                                formControlName="cpDate"
                                timeZone="utc"
                                [opsValidationPopover]="cpDateValidation"
                                data-test-charterparty-date
                            ></ops-date-input>
                            <ng-template #cpDateValidation>
                                <ops-simple-field-validation
                                    fieldDisplayName="CP Date"
                                    [control]="headingForm.controls['cpDate']"
                                ></ops-simple-field-validation>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="form-group col-3" *opsDivisionsExclude="Division.tankers">
                    <label for="cpType">CP Type</label>
                    <ng-select
                        class="ops-select form-control"
                        [items]="cpTypes$ | async"
                        placeholder="Select CP Type"
                        formControlName="charterPartyType"
                        [clearable]="true"
                        bindLabel="name"
                        id="cpType"
                        [compareWith]="referenceDataService.compare"
                        [selectOnTab]="true"
                        data-test-charterparty-type
                    >
                    </ng-select>
                </div>
                <div class="form-group col-6">
                    <label for="cpmLink">Charter Party Link</label>
                    <input id="cpmLink" class="form-control" formControlName="cpmLink" [opsValidationPopover]="cpLinkValidation" data-test-cpmLink />
                    <ng-template #cpLinkValidation>
                        <ops-simple-field-validation
                            [userMessages]="{ pattern: 'Must be a CPM or Recap Manager link. Please include https://' }"
                            fieldDisplayName="Charter Party Link"
                            [control]="headingForm.controls['cpmLink']"
                        ></ops-simple-field-validation>
                    </ng-template>
                </div>
            </div>

            <div class="row no-gutters">
                <div class="form-group col-3">
                    <label for="voyageReference">Voyage Ref</label>
                    <input id="voyageReference" class="form-control" formControlName="voyageReference" data-test-voyage-reference />
                </div>
                <div class="form-group col-3">
                    <label for="chartererReference">Charterer Ref</label>
                    <input id="chartererReference" class="form-control" formControlName="chartererReference" data-test-charterer-reference />
                </div>
                <div class="form-group col-3">
                    <label for="chartererReference2">Charterer Ref 2</label>
                    <input id="chartererReference2" class="form-control" formControlName="chartererReference2" data-test-charterer-reference />
                </div>
                <div class="form-group col-3">
                    <label for="ownerReference">Owner Ref</label>
                    <input id="ownerReference" class="form-control" formControlName="ownerReference" data-test-owner-reference />
                </div>
            </div>

            <div class="row no-gutters">
                <div class="form-group col-3 pl-0 pr-0">
                    <div class="row no-gutters">
                        <div class="form-group col-6 pt-0 pb-0">
                            <label for="brokerCommission" class="no-space-break">Broker Comm. (%)</label>
                            <input
                                id="brokerCommission"
                                class="form-control text-right"
                                formControlName="brokerCommission"
                                [opsNumber]="{ precision: 3 }"
                                data-test-broker-commission />
                        </div>
                        <div class="form-group col-6 pt-0 pb-0">
                            <label for="addressCommission" class="no-space-break">Address Comm. (%)</label>
                            <input
                                id="addressCommission"
                                class="form-control text-right"
                                formControlName="addressCommission"
                                [opsNumber]="{ precision: 3 }"
                                data-test-address-commission />
                        </div>
                    </div>
                </div>
                <div class="form-group col-3 pl-0 pr-0">
                    <div class="row no-gutters">
                        <div class="form-group col-6 pt-0 pb-0">
                            <label class="no-space-break">Vessel Nomination</label>
                            <ops-date-input
                                formControlName="vesselNominationDate"
                                timeZone="utc"
                                [defaultFocusDate]="headingForm.controls['cpDate'].value"
                                data-test-vessel-nomination
                            ></ops-date-input>
                        </div>
                        <div class="form-group col-6 pt-0 pb-0">
                            <label class="no-space-break">Lifting Declaration</label>
                            <ops-date-input
                                formControlName="liftingDate"
                                timeZone="utc"
                                [defaultFocusDate]="headingForm.controls['cpDate'].value"
                                data-test-lifting-declaration
                            ></ops-date-input>
                        </div>
                    </div>
                </div>
                <div class="form-group col-3">
                    <label for="liftingNumber">Lifting Number</label>
                    <input id="liftingNumber" formControlName="liftingNumber" class="form-control" data-test-lifting-number />
                </div>
                <div class="form-group col-3" *ngIf="fixture.fixtureSource.id === FixtureSource.Ops">
                    <label>CoA Reference</label>
                    <input id="coANumber" formControlName="coANumber" class="form-control" maxlength="250" data-test-coa-number />
                </div>
            </div>

            <div class="row no-gutters" *opsDivisions="Division.pcg">
                <div class="form-group col-3">
                    <label for="numberOfVesselChanges">No. Of Vessel Changes</label>
                    <input
                        id="numberOfVesselChanges"
                        formControlName="numberOfVesselChanges"
                        class="form-control"
                        [opsNumber]="{ precision: 0 }"
                        data-test-number-of-vessel-changes
                    />
                </div>
                <div class="form-group col-3">
                    <div class="label-with-checkbox">
                        <label for="presentationType">Presentation Type</label>
                        <div class="checkbox" *opsDivisions="Division.pcg">
                            <label
                                for="includePresentationInKpi"
                                title="Click to include a purged vessel against the Suitable Last Cargo metric"
                            >KPI</label>
                            <input
                                id="includePresentationInKpi"
                                type="checkbox"
                                name="includePresentationInKpi"
                                formControlName="includePresentationInKpi"
                                data-test-include-presentation-in-kpi
                            />
                        </div>
                    </div>
                    <ng-select
                        id="presentationType"
                        class="ops-select form-control"
                        formControlName="presentationType"
                        [compareWith]="referenceDataService.compare"
                        [items]="presentationTypes$ | async"
                        [clearable]="true"
                        bindLabel="name"
                        [selectOnTab]="true"
                        data-test-presentation-type
                    ></ng-select>
                </div>
            </div>
        </div>

        <div class="col-5">
            <div class="row no-gutters">
                <div class="form-group col-4 required">
                    <label title="Freight Type" for="freightType">Freight Type</label>
                    <ng-select
                        data-cy="freight-type"
                        id="freightType"
                        class="ops-select form-control"
                        formControlName="freightType"
                        [compareWith]="referenceDataService.compare"
                        [items]="freightTypes$ | async"
                        [clearable]="false"
                        bindLabel="name"
                        [selectOnTab]="true"
                        data-test-freight-type
                    >
                    </ng-select>
                </div>

                <div class="form-group col-4" [class.required]="isLumpSumValueRequired">
                    <label for="lumpsumValue">Lump Sum Value</label>
                    <input
                        data-cy="lump-sum-value"
                        id="lumpsumValue"
                        class="form-control"
                        formControlName="lumpsumValue"
                        [opsNumber]="{ precision: 2 }"
                        [opsValidationPopover]="lumpsumValidation"
                        data-test-lumpsum-value
                    />

                    <ng-template #lumpsumValidation>
                        <ops-simple-field-validation
                            fieldDisplayName="Lump Sum Value"
                            [control]="headingForm.controls['lumpsumValue']"
                        ></ops-simple-field-validation>
                    </ng-template>
                </div>

                <div class="form-group col-4 required">
                    <label title="Demurrage Status" for="demurrageStatus">Dem. Status</label>
                    <ng-select
                        id="demurrageStatus"
                        class="ops-select form-control"
                        formControlName="demurrageStatus"
                        warningFormControl
                        [compareWith]="referenceDataService.compare"
                        [items]="demurrageStatuses$ | async"
                        [clearable]="false"
                        bindLabel="name"
                        [selectOnTab]="true"
                        [opsValidationPopover]="demurrageStatusValidation"
                        data-test-demurrage-status>
                    </ng-select>

                    <ng-template #demurrageStatusValidation>
                        <ops-simple-field-validation
                            fieldDisplayName="Dem. Status"
                            [control]="headingForm.controls['demurrageStatus']"
                        ></ops-simple-field-validation>
                    </ng-template>
                </div>
            </div>

            <div class="row no-gutters">
                <div class="form-group col d-flex flex-column-reverse comments">
                    <div class="no-gutters comments-controls" [class.important-notice-visible]="headingForm.controls.isCommentsImportant.value">
                        <div class="important-notice d-flex" data-test-important-notice>
                            <div class="comments-important-icon has-icon icon--info-outline"></div>
                            <span>IMPORTANT!</span>
                        </div>
                        <textarea class="form-control" id="comments" name="comments" formControlName="comments" maxlength="3000" data-test-comments></textarea>
                    </div>
                    <div class="label-with-checkbox">
                        <label for="comments">Comments</label>
                        <div class="checkbox">
                            <label for="isCommentsImportant">Important</label>
                            <input
                                type="checkbox"
                                id="isCommentsImportant"
                                name="isCommentsImportant"
                                formControlName="isCommentsImportant"
                                data-test-is-comments-important
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
